@import '../../styles/main.scss';

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  height: 46px;
  border: 1px solid $color-white-darker;
  padding-left: 10px;
  background: $color-white;
  min-width: 300px;
  max-width: 300px;
  z-index: 13;
  span {
    background-color: transparent !important;
    color: $color-text !important;
    text-align: left !important;
    font-size: unset !important;
    padding-left: 0px !important;
  }
}

.loading {
  border: 1px solid $color-gray-light;
  pointer-events: none;
  text-align: center;
  justify-content: center;
}

.disabled {
  border: 1px solid $color-gray-light;
  //pointer-events: none;
  text-align: center;
  background-color: rgba($color-gray-light, 0.2);
}

.selectIcons {
  display: flex;
  align-items: stretch;
  position: absolute;
  right: 10px;
  top: 10px;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
  font-size: 18px;
  i {
    height: 32px;
  }

  &-opened {
    top: 2px !important;
    i {
      transform: rotate(180deg);
    }
  }
}

.firstDropdown {
  position: absolute;
  z-index: 11;
  top: calc(100% - 4px);
  right: 0;
  min-width: 250px;
  border-radius: 2px;
  background-color: $color-white;
  box-shadow: 0px 1px 8px 0px $color-gray;
  color: $color-black;
  width: 100%;

  &.wide {
    left: 0;
    right: auto;
    min-width: 300px;
  }

  span {
    display: block;
    padding: 15px 20px 0;
    font-size: 12px;
    color: $color-gray-light;
    text-transform: uppercase;
    border-top: 1px solid $color-gray-light;

    &:first-of-type {
      border-top: none;
    }

    &.empty {
      padding-top: 0;
    }
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    transition: color 200ms;
    cursor: pointer;

    div {
      display: flex;
      width: 100%;
    }

    i {
      margin-right: 10px;
      color: $color-gray-lightest;
      transition: color 200ms;

      &:nth-of-type(2) {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &:hover {
      color: var(--link-color);

      i {
        color: var(--link-color);
      }
    }
  }

  li:hover {
    color: var(--primary-color);

    i {
      color: var(--primary-color);
    }
  }

  .loader {
    padding: 15px 20px;
  }
}

.secondDropdown {
  position: absolute;
  z-index: 11;
  top: 0;
  left: calc(100%);
  min-width: 300px;
  max-width: 300px;
  border-radius: 2px;
  background-color: $color-white;
  box-shadow: 0px 1px 8px 0px $color-gray;
  color: $color-black;
  width: 100%;
  max-height: 500px;

  &.wide {
    left: 0;
    right: auto;
    min-width: 300px;
  }

  span {
    display: block;
    padding: 15px 20px 0;
    font-size: 12px;
    color: $color-gray-light;
    text-transform: uppercase;
    border-top: 1px solid $color-gray-light;

    &:first-of-type {
      border-top: none;
    }

    &.empty {
      padding-top: 0;
    }
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    transition: color 200ms;
    cursor: pointer;

    div {
      display: flex;
      width: 100%;
    }

    i {
      margin-right: 10px;
      color: $color-gray-lightest;
      transition: color 200ms;

      &:nth-of-type(2) {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &:hover {
      color: var(--link-color);

      i {
        color: var(--link-color);
      }
    }
  }

  li:hover {
    color: var(--primary-color);

    i {
      color: var(--primary-color);
    }
  }

  .loader {
    padding: 15px 20px;
  }
}

.thirdDropdown {
  position: absolute;
  z-index: 13;
  top: 0;
  left: calc(100%);
  min-width: 300px;
  max-width: 300px;
  border-radius: 2px;
  background-color: $color-white;
  box-shadow: 0px 1px 8px 0px $color-gray;
  color: $color-black;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;

  &.wide {
    left: 0;
    right: auto;
    min-width: 300px;
  }

  span {
    display: block;
    padding: 15px 20px 0;
    font-size: 12px;
    color: $color-gray-light;
    text-transform: uppercase;
    border-top: 1px solid $color-gray-light;

    &:first-of-type {
      border-top: none;
    }

    &.empty {
      padding-top: 0;
    }
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    transition: color 200ms;
    cursor: pointer;

    div {
      display: flex;
      width: 100%;
    }

    i {
      margin-right: 10px;
      color: $color-gray-lightest;
      transition: color 200ms;

      &:nth-of-type(2) {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &:hover {
      color: var(--link-color);

      i {
        color: var(--link-color);
      }
    }
  }

  li:hover {
    color: var(--primary-color);

    i {
      color: var(--primary-color);
    }
  }

  .loader {
    padding: 15px 20px;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.active {
  color: var(--link-color);
}
